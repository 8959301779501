import { checkPermission } from '@/utils'

export const tableOption = {
  border: true,
  editBtn: false,
  delBtn: false,
  search: checkPermission(['externaladmin:marketingManagement:userBehavior:search']),
  //根据当前表格宽度设置超出隐藏
  showOverflowTooltip: true,
  height: '100%',
  searchSize: 'small',
  column: [
    {
      label: '注册时间',
      prop: 'createTime',
      hide: true,
      search: true,
      searchWidth: 400,
      searchLabelWidth: 80,
      type: 'daterange'
    },
    {
      label: '用户名',
      prop: 'userName',
      slot: true
    },
    {
      label: '用户账号',
      searchPlaceholder: '请输入用户名/账号/邮箱',
      prop: 'name',
      searchLabelWidth: 130,
      searchWidth: 350,
      hide: true,
      search: true
    },
    {
      label: '账号',
      prop: 'accountName',
      slot: true
    },
    {
      label: '邮箱',
      prop: 'email',
      slot: true
    },
    {
      label: '主营平台',
      prop: 'platformCode',
      type: 'select',
      searchWidth: 350,
      dicData: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: 1,
          label: 'Amazon'
        },
        {
          value: 2,
          label: 'AliExpress'
        },
        {
          value: 3,
          label: '1688'
        },
        {
          value: 4,
          label: 'etsy'
        },
        {
          value: 5,
          label: 'Ebay'
        },
        {
          value: 6,
          label: 'TMALL天猫'
        },
        {
          value: 7,
          label: '淘宝'
        },
        {
          value: 8,
          label: '京东'
        },
        {
          value: 9,
          label: '阿里巴巴国际站'
        },
        {
          value: 10,
          label: '独立站'
        },
        {
          value: 0,
          label: '其他'
        }
      ],
      hide: true,
      search: true
    },
    {
      label: '主营平台',
      prop: 'platform',
      slot: true
    },
    {
      label: '类目',
      prop: 'category',
      searchFormSlot: true,
      hide: true,
      search: true,
      searchWidth: 350
    },
    {
      label: '主营类目',
      prop: 'categoryName',
      slot: true
    },
    {
      label: '公司规模',
      prop: 'companySize',
      type: 'select',
      searchWidth: 350,
      width: 80,
      dicData: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: 1,
          label: '1~5人'
        },
        {
          value: 2,
          label: '5~10人'
        },
        {
          value: 3,
          label: '10~50人'
        },
        {
          value: 4,
          label: '50人以上'
        }
      ],
      search: true
    },
    {
      label: '成立时间',
      prop: 'establishDuration',
      type: 'select',
      width: 80,
      searchWidth: 350,
      dicData: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: 1,
          label: '1年以内'
        },
        {
          value: 2,
          label: '1~3年'
        },
        {
          value: 3,
          label: '3年以上'
        }
      ],
      search: true
    },
    {
      label: '注册时间',
      prop: 'time',
      slot: true
    },
    {
      label: '标记状态',
      prop: 'remarkStatus',
      type: 'select',
      hide: true,
      searchWidth: 350,
      dicData: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: 0,
          label: '未标记'
        },
        {
          value: 1,
          label: '已标记'
        }
      ],
      search: true
    },
    {
      label: '标记',
      prop: 'remark',
      slot: true
    }
  ]
}
